// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import { ConfigureAmplify } from '../../../helpers/amplify';
import styles from './AdminLoginForm.styles';
import { Formik } from 'formik';
import //{
AdminLoginFormControls from './AdminLoginFormControls'; //} // AdminLoginForgotPassword // AdminLoginNewPassword,
// import AdminLoginFormControlsBadgeLogin from './AdminLoginFormControlsBadgeLogin';
import * as Yup from 'yup';
import { injectIntl, defineMessages } from 'react-intl';
import { readSettings } from '../../../helpers/board';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('iLocalBox');

const messages = defineMessages({
  emailInstruction: {
    id: 'Login.EmailInstruction',
    defaultMessage: 'Enter your email address'
  },
  emailInvalid: {
    id: 'Login.EmailInvalid',
    defaultMessage: 'Enter a valid email address'
  },
  emailRequired: {
    id: 'Login.EmailRequired',
    defaultMessage: 'Email address is required'
  },
  passwordInstruction: {
    id: 'Login.PasswordInstruction',
    defaultMessage: 'Enter your password'
  },
  passwordLength: {
    id: 'Login.PasswordLength',
    defaultMessage: 'Password must contain at least 8 characters'
  },
  passwordUppercase: {
    id: 'Login.PasswordUppercase',
    defaultMessage: 'Password must contain at least one uppercase character'
  },
  passwordLowercase: {
    id: 'Login.PasswordLowercase',
    defaultMessage: 'Password must contain at least one lowercase character'
  },
  passwordNumeric: {
    id: 'Login.PasswordNumeric',
    defaultMessage: 'Password must contain at least one numeric character'
  },
  passwordRequired: {
    id: 'Login.PasswordRequired',
    defaultMessage: 'Password is required'
  },
  UserNotFoundException: {
    id: 'Login.UserNotFoundException',
    defaultMessage: 'User does not exist.'
  },
  NotAuthorizedException: {
    id: 'Login.NotAuthorizedException',
    defaultMessage: 'Incorrect username or password.'
  },
  NotAuthorizedExceptionDisabled: {
    id: 'Login.NotAuthorizedExceptionDisabled',
    defaultMessage: 'User is disabled.'
  },
  PasswordResetRequiredException: {
    id: 'Login.PasswordResetRequiredException',
    defaultMessage: 'Password reset required for the user.'
  },
  ChangePassword: {
    id: 'Login.ChangePassword',
    defaultMessage: 'Please change your password.'
  },
  Welcome: {
    id: 'Login.Welcome',
    defaultMessage: 'Welcome'
  },
  passwordsMustMatch: {
    id: 'Login.PasswordsMustMatch',
    defaultMessage: 'Passwords must match'
  },
  ForgotPasswordInstruction: {
    id: 'Login.ForgotPasswordInstruction',
    defaultMessage: 'Reset password with verification code'
  },
  ExpiredCodeException: {
    id: 'Login.ExpiredCodeException',
    defaultMessage: 'Verification code is invalid.'
  },
  forgotpassword: {
    id: 'Login.ForgotPassword',
    defaultMessage: 'Forgot Password?'
  },
  contactsupport: {
    id: 'Login.ContactSupport',
    defaultMessage: 'Contact Support'
  },
  SignIn: {
    id: 'Login.SignIn',
    defaultMessage: 'Sign In'
  },
  badgeIdInstruction: {
    id: 'Login.BadgeIdInstruction',
    defaultMessage: 'Enter your BadgeID'
  },
  badgeIdRequired: {
    id: 'Login.BadgeIdRequired',
    defaultMessage: 'badgeID Required'
  },
  badgeIdAlphaNumeric: {
    id: 'Login.BadgeIdAlphaNumeric',
    defineMessages: 'BadgeID must be Alpha Numeric'
  },
  badgeIdLength: {
    id: 'Login.BadgeIdLength',
    defaultMessage: 'BadgeID must contain at least 8 characters'
  }
});

export class AdminLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruction: 'Please login to continue',
      usernameError: false,
      passwordError: false,
      badgeiderror: false,
      nameError: false,
      user: null,
      toggleBadge: false,
      isInProgress: false
    };
    this.childHandler = this.childHandler.bind(this);
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    loginHandler: PropTypes.func,
    user: PropTypes.object,
    error: PropTypes.object,
    NEW_PASSWORD_REQUIRED: PropTypes.bool,
    PASSWORD_RESET_REQUIRED: PropTypes.bool,
    onForgotPasswordRequest: PropTypes.func,
    onContactSupportRequest: PropTypes.func,
    FORGOT_PASSWORD_REQUEST: PropTypes.bool,
    username: PropTypes.string,
    onGetCode: PropTypes.func,
    onForgotPasswordSubmit: PropTypes.func,
    onRequestSignIn: PropTypes.func
  };
  childHandler() {
    this.props.history.replace('/SecureBadgeLogin');
  }
  componentDidMount() {
    ConfigureAmplify();
  }
  componentWillMount() {
    localStorage.removeItem('permissionUser');
    localStorage.removeItem('permissions');
    localStorage.removeItem('VidyoCore::SelectedMicrophone');
    localStorage.removeItem('VidyoCore::SelectedSpeaker');
    localStorage.removeItem('VidyoCore::EndpointID');
    localStorage.removeItem('VidyoCore::SelectedCamera');

    let n = sessionStorage.length;
    while (n--) {
      let key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
  }

  // signIn = formData => {
  //   // this.props.onRequestSignIn();
  //   Auth.signIn(formData.username, formData.password)
  //     .then(user => {
  //       // sessionStorage.setItem('user', JSON.stringify(user));
  //       sessionStorage.setItem(
  //         'jwtToken',
  //         JSON.stringify(user.signInUserSession.idToken.jwtToken)
  //       );
  //       this.props.history.push('/RearStock/AdminPanel');
  //     })
  //     .catch(err => {
  //       switch (err.code) {
  //         case 'UserNotFoundException':
  //           this.setState({ instruction: err.message, usernameError: true });
  //           break;
  //         case 'NotAuthorizedException':
  //         default:
  //           this.setState({
  //             instruction: 'Invalid username or password.',
  //             usernameError: true,
  //             passwordError: true
  //           });
  //       }
  //     });
  // };
  signIn = (formData) => {
    let box_id = '';
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      box_id = fileData ? fileData.boxId : false;
    } else {
      box_id = localStorage.getItem('boxId');
    }

    let objSignInUser = {
      username: formData.username,
      box_id: box_id
    };
    this.setState({ isInProgress: true });
    fetch(process.env.REACT_APP_BASEURL + 'authUser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objSignInUser)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then((objUser) => {
        let objUserDetais = JSON.parse(objUser);
        if (objUserDetais && objUserDetais.isValidUser === true) {
          Auth.signIn(formData.username, formData.password)
            .then((user) => {
              // sessionStorage.setItem('user', JSON.stringify(user));
              sessionStorage.setItem(
                'jwtToken',
                JSON.stringify(user.signInUserSession.idToken.jwtToken)
              );
              //Get Permissions for Login user
              sessionStorage.setItem('user', JSON.stringify(user));
              fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
                method: 'GET',
                withCredentials: true,
                headers: {
                  Authorization:
                    'Bearer ' +
                    JSON.parse(sessionStorage.getItem('user')).signInUserSession
                      .idToken.jwtToken,
                  'Content-Type': 'application/json'
                }
              })
                .then((response) => response.json())
                .then((Permissons) => {
                  if (Permissons.permission) {
                    const encryptedString = cryptr.encrypt(
                      JSON.stringify(Permissons.permission)
                    );
                    //console.warn(encryptedString);
                    // const decryptedString = cryptr.decrypt(encryptedString);
                    // console.warn(JSON.parse(decryptedString));
                    localStorage.setItem('permissions', encryptedString);
                  }
                  if (Permissons.superAdmin) {
                    localStorage.setItem(
                      'permissionUser',
                      Permissons.superAdmin
                    );
                  }
                  this.setState({ isInProgress: false });
                  this.props.history.push('/RearStock/AdminPanel');
                  this.props.history.push('/RearStock/AdminPanel');
                  //navigate(`/home`);
                });
            })
            .catch((err) => {
              this.setState({ isInProgress: false });
              switch (err.code) {
                case 'UserNotFoundException':
                  this.setState({
                    instruction: err.message,
                    usernameError: true
                  });
                  break;
                case 'NotAuthorizedException':
                default:
                  this.setState({
                    instruction: 'Invalid username or password.',
                    usernameError: true,
                    passwordError: true
                  });
              }
            });
        } else {
          this.setState({ isInProgress: false });
          this.setState({
            instruction: 'Invalid username or password.',
            usernameError: true,
            passwordError: true
          });
        }
      });

    // // this.props.onRequestSignIn();
  };

  // signInWithBadgeId = (formData) => {
  //   // this.props.onRequestSignIn();
  //   Auth.signIn(formData.badgeId)
  //     .then((user) => {
  //       // sessionStorage.setItem('user', JSON.stringify(user));
  //       sessionStorage.setItem(
  //         'jwtToken',
  //         JSON.stringify(user.signInUserSession.idToken.jwtToken)
  //       );
  //       this.props.history.push('/RearStock/AdminPanel');
  //     })
  //     .catch((err) => {
  //       switch (err.code) {
  //         case 'UserNotFoundException':
  //           this.setState({ instruction: err.message, badgeiderror: true });
  //           break;
  //         case 'NotAuthorizedException':
  //         default:
  //           this.setState({
  //             instruction: 'Invalid badgeID',
  //             badgeiderror: true
  //           });
  //       }
  //     });
  // };

  forgotPassword = (e) => {
    this.props.onForgotPasswordRequest(this.props.values);
    e.preventDefault();
  };

  render() {
    const {
      classes,
      // isAuthenticated,
      // user,
      // login,
      // completeNewPasswordChallenge,
      intl,
      // error,
      // NEW_PASSWORD_REQUIRED,
      // PASSWORD_RESET_REQUIRED,
      // FORGOT_PASSWORD_REQUEST,
      // CONTACT_SUPPORT_REQUEST,
      onForgotPasswordRequest,
      onContactSupportRequest
      // username,
      // onGetCode,
      // onForgotPasswordSubmit,
      // onRequestSignIn
    } = this.props;
    const values = { username: '', password: '', badgeId: '' };

    const validationSchema = Yup.object({
      username: Yup.string(intl.formatMessage(messages.emailInstruction))
        .email(intl.formatMessage(messages.emailInvalid))
        .required(intl.formatMessage(messages.emailRequired)),
      password: Yup.string(intl.formatMessage(messages.passwordInstruction))
        .min(8, intl.formatMessage(messages.passwordLength))
        .matches(/[A-Z]/, intl.formatMessage(messages.passwordUppercase), true)
        .matches(/[a-z]/, intl.formatMessage(messages.passwordLowercase), true)
        .matches(/[0-9]/, intl.formatMessage(messages.passwordNumeric), true)
        .required(intl.formatMessage(messages.passwordRequired))
    });

    // const validationSchemaBadgeId = Yup.object({
    //   badgeId: Yup.string(intl.formatMessage(messages.badgeIdInstruction))
    //     .required(intl.formatMessage(messages.badgeIdRequired))
    //     .min(8, intl.formatMessage(messages.badgeIdLength))
    //     .matches(
    //       /^[A-za-z0-9]+$/,
    //       intl.formatMessage(messages.badgeIdAlphaNumeric),
    //       true
    //     )
    // });

    return (
      <div className={classNames(classes.container)}>
        {/* <div className={classNames(classes.appName)}>Sign In</div> */}
        <div className={classNames(classes.appLogoContainer)}>
          {/* <img
            className={classNames(classes.appLogo)}
            alt="app-logo"
            src="/images/brands/default/pickupcode-screen.png"
          /> */}
        </div>

        <div className={classNames(classes.Instructiontitle)}>
          {this.state && this.state.instruction}
        </div>
        <div className={classNames(classes.form)}>
          {/* {this.state.toggleBadge ? ( */}
          <Formik
            render={(props) => (
              <AdminLoginFormControls
                {...props}
                onForgotPasswordRequest={onForgotPasswordRequest}
                onContactSupportRequest={onContactSupportRequest}
                action={this.childHandler}
                isInProgress={this.state.isInProgress}
              />
            )}
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(formData, formActions) => {
              this.signIn(formData);
              // login(formData, formActions); // pass context as third argument like: {someKey 'someValue'} if required
            }}
          />
          {/* // ) : ( */}
          {/* //   <Formik
          //     render={(props) => (
          //       <AdminLoginFormControlsBadgeLogin
          //         {...props}
          //         onForgotPasswordRequest={onForgotPasswordRequest}
          //         onContactSupportRequest={onContactSupportRequest}
          //         action={this.childHandler}
          //       />
          //     )}
          //     validationSchema={validationSchemaBadgeId}
          //     initialValues={values}
          //     onSubmit={(formData, formActions) => {
          //       this.signInWithBadgeId(formData);
          //       // login(formData, formActions); // pass context as third argument like: {someKey 'someValue'} if required
          //     }}
          //   />
          // )} */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminLoginForm))
);
